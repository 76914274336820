import * as React from "react"
import styled from "styled-components"
import { FC, useRef } from "react"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import BannerLayout from "../../components/layout/BannerLayout"
import ColumnsLayout from "../../components/layout/ColumnsLayout"
import ProductList from "../../components/product-list/ProductList"
import VideoEmbed from "../../../components/layout/video-embed/VideoEmbed"
import PopularitySorter from "../../components/product-list/sorters/PopularitySorter"
import GImage from "../../../gatsby/GImage"
import ImpactIcons from "../../../components/impact-icons/ImpactIcons"
import { tablet } from "../../constants/screenSizes"
import { PrimaryButton } from "@social-supermarket/social-supermarket-components"
import SecondaryHeader from "../../components/title/SecondaryHeader"
import UnderlineHeader from "../../components/title/UnderlineHeader"
import { BrandType, ProductType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div``
const ProductListContainer = styled.div`
  max-width: 1100px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
`
const ImpactOneLine = styled.div`
  font-size: 1.3em;
  margin-bottom: 20px;
  margin-top: 20px;
`
const Description = styled.div`
  margin-bottom: 20px;
`
const FounderName = styled.p`
  font-size: 0.9em;
`
const Right = styled.div`
  padding-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledPrimaryButton = styled(PrimaryButton)`
  max-width: 500px;
  margin: 30px auto 30px;
`
const StyledVideoEmbed = styled(VideoEmbed)`
  margin: 20px 0 40px;
  padding: 20px 0 0;
`
const StyledSecondaryHeader = styled(SecondaryHeader)`
  border-top: 1px solid #eaebeb;
  padding-top: 60px;
  max-width: 1200px;
  margin: auto;
  margin-top: 40px;
`
const StyledUnderlineHeader = styled(UnderlineHeader)`
  margin-top: 40px;
  margin-bottom: 10px;
`
const StyledImpactIcons = styled(ImpactIcons)`
  justify-content: center;
`
const BestSellers = styled(ProductList)`
  max-width: 250px;

  @media (max-width: ${tablet}) {
    max-width: unset;
  }
`

interface Props {
  brand: BrandType
  products: ProductType[]
}

const BrandPage: FC<Props> = ({
  brand: {
    name,
    image,
    impactOneLine,
    description,
    banner,
    videoEmbed,
    meetTheFounder,
    founderTitle,
    founderNames,
    founderProfilePhoto,
    impactIcons,
  },
  products,
}) => {
  const productRef = useRef(null)

  const handleClick = () => {
    window.scrollTo({
      top: productRef.current.offsetTop - 200,
      left: 0,
      behavior: "smooth",
    })
  }

  const renderLeft = () => {
    return (
      <div>
        {products?.length > 0 && (
          <StyledPrimaryButton onClick={handleClick}>See Full Range</StyledPrimaryButton>
        )}
        <ImpactOneLine>{impactOneLine}</ImpactOneLine>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        {videoEmbed && <StyledVideoEmbed url={videoEmbed} />}
        {meetTheFounder && (
          <div>
            <ImpactOneLine>Meet The Founder</ImpactOneLine>
            <Description dangerouslySetInnerHTML={{ __html: meetTheFounder }} />
          </div>
        )}
      </div>
    )
  }

  const renderRight = () => {
    const popularProducts = PopularitySorter.sort(products).slice(0, 2)
    return (
      <Right>
        {founderNames && (
          <StyledUnderlineHeader>{founderTitle || "Founded By"}</StyledUnderlineHeader>
        )}
        {founderNames && <FounderName>{founderNames}</FounderName>}
        {founderProfilePhoto && (
          <GImage
            image={founderProfilePhoto}
            style={{ maxWidth: 200, width: "100%", marginBottom: 10 }}
          />
        )}
        <StyledUnderlineHeader>Key Brand Points</StyledUnderlineHeader>
        <StyledImpactIcons slugString={impactIcons} columns={4} />
        {popularProducts?.length > 0 && <StyledUnderlineHeader>Best Sellers</StyledUnderlineHeader>}
        {popularProducts?.length > 0 && (
          <BestSellers products={popularProducts} collectionName={`Brand Best Sellers: ${name}`} />
        )}
      </Right>
    )
  }

  return (
    <Layout fullWidth>
      <Seo title={`${name}: ${impactOneLine}`} description={description} />
      <Container>
        <BannerLayout bannerImage={banner} logoImage={image} title={name} />
        <ColumnsLayout renderMajor={renderLeft} renderMinor={renderRight} />
        {products?.length > 0 && <StyledSecondaryHeader>Full Product Range</StyledSecondaryHeader>}
        {products?.length > 0 && (
          <ProductListContainer ref={productRef}>
            <ProductList products={products} collectionName={`Brand: ${name}`} />
          </ProductListContainer>
        )}
      </Container>
    </Layout>
  )
}

export default BrandPage
