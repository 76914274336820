import React, { Component } from "react"
import * as style from "./VideoEmbed.module.scss"
import cx from "classnames"

class VideoEmbed extends Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
    this.state = {
      width: null,
    }
  }

  componentDidMount() {
    this.setState({
      width: this.containerRef.current.offsetWidth,
    })
  }

  render() {
    const { url, className } = this.props
    return (
      <div
        ref={this.containerRef}
        style={{ height: this.state.width * 0.5625 }}
        className={cx(style.videoEmbed, className)}
      >
        {getEmbedCode(url)}
      </div>
    )
  }
}

const getEmbedCode = url => {
  if (url.includes("watch?v=")) {
    const videoId = getId(url)
    return (
      <iframe src={`https://www.youtube.com/embed/${videoId}`} frameborder="0" allowfullscreen />
    )
  } else if (url.includes("vimeo.com")) {
    const id = url.split("/")[url.split("/").length - 1]
    return <iframe src={`https://player.vimeo.com/video/${id}`} frameBorder="0" allowFullScreen />
  }
}

const getId = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  return match && match[2].length === 11 ? match[2] : null
}

export default VideoEmbed
